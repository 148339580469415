import React, { useEffect } from "react";
import Live from "./Live";
import { useUserSettings } from "../components/UserSettingsProvider/UserSettingsProvider";
import { checkLogin } from "../data/firebase";

export const HomePage = () => {
  
  const { userSettings, updateSettings } = useUserSettings();
  //const [goLive, setGoLive] = React.useState(false);

  useEffect(() => {
    if (userSettings.site.id) {
      checkLogin().then(() => {
        //setGoLive(true);
      });
    }
  }, [userSettings]);

  //if (!goLive) {
  //  return <div>Connecting to live dashboard...</div>;
  //}

  return(
      <Live
        siteObj={
          {
            "siteID": userSettings.site.id,
            "siteCode": userSettings.site.code,
            "senseCode": userSettings.site.code,
          }
        }
      />
    );
}


