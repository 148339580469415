import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { getInitialsFromName } from '../functions/dataHandler';
import { LogoutButton } from './buttons/logout-button';
import { useUserSettings } from './UserSettingsProvider/UserSettingsProvider';
import SetSelectedSite from '../Pages/Settings/UserManagement/setSelectedSite';
import { useEffect } from 'react';

export function UserMenu({username}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userSettings, updateSettings } = useUserSettings();
  const [site, setSite] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    //auth.logout();
    setAnchorEl(null);
  };

  function getUsername(){
    try {
      const usr = userSettings.user;
      return `${usr.firstName} ${usr.surname}`;
    }
    catch(e){

    }
  }
  function getSiteName(){
    var tmp ='';
    if(userSettings!==null && userSettings!==undefined )
      if(userSettings.site!==null && userSettings.site!==undefined )
        if(userSettings.site.name!==null && userSettings.site.name!==undefined )
          tmp = userSettings.site.name[0].toUpperCase() + userSettings.site.name.slice(1).toLowerCase();

    return tmp;
  }
  function titleCase(string){
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(()=>{ 
      if (useUserSettings&&userSettings!==null){
        setSite(userSettings.site);
      }
  }
  ,[userSettings])


  console.log("userSettings", userSettings);

  return (
    <React.Fragment>
      
        <h5>
        { userSettings!==null?
            getSiteName() :
            null
            }
        </h5>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{getInitialsFromName(getUsername())}</Avatar>
          </IconButton>
        </Tooltip>

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
     {/*}
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <MoveSVG />
          </ListItemIcon>
          <a href="move">Move</a>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
           <VirtualHomeSVG />
          </ListItemIcon>
          <a href="virtual">Virtual Home</a>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <VirtualHomeSVG />
          </ListItemIcon>
          <a href="onboarding">Onboarding</a>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <SettingsSVG />
          </ListItemIcon>
          <a href="settings">Settings</a>
        </MenuItem>
      */}
        <Divider />
        <LogoutButton>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ExitSVG />
           </ListItemIcon>
          <span className='aqDefaultText'>Log out</span>
        </MenuItem>
        </LogoutButton>  
      </Menu>
    </React.Fragment>
  );
/*
  return (
    <React.Fragment>
      
      {(site==null)?
        <h5>
        { userSettings!==null?
            getSiteName() :
            null
            }
        </h5>
      :
        <SetSelectedSite name={site.name} value={site.id} onChange={(siteID)=>{
          //reload the entire page
          if (siteID!==site.id) window.location.reload();

        }} userID={userSettings.user.id} />
      }
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{getInitialsFromName(getUsername())}</Avatar>
          </IconButton>
        </Tooltip>

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Divider />
        <LogoutButton>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ExitSVG />
           </ListItemIcon>
          <span className='aqDefaultText'>Log out</span>
        </MenuItem>
        </LogoutButton>  
      </Menu>
    </React.Fragment>
  );
  */
}


export function UserMenuTop({displayName}) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    //auth.logout();
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{getInitialsFromName(displayName)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
  
        <MenuItem>
          <ListItemIcon>
           <VirtualHomeSVG />
          </ListItemIcon>
          <a href="virtual">Virtual Home</a>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <VirtualHomeSVG />
          </ListItemIcon>
          <a href="onboarding">Onboarding</a>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <SettingsSVG />
          </ListItemIcon>
          <a href="settings">Settings</a>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ExitSVG />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}


const SettingsSVG = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );

}

const ExitSVG = () => {

  return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
      </svg>
  )
}

const MoveSVG = () => {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
}


const VirtualHomeSVG = () => {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
    </svg>
  )
}